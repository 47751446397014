@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: #f9f9f9;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
input,
button,
a,
li {
  font-family: 'Barlow Condensed', sans-serif;
}

section {
  overflow: hidden;
}


.main-title {
  font-size: 35px;
  font-weight: 700;
  color: #1E293B;
}


.Toastify__toast-body {
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 400;
  font-size: 18px;
}


.loader2 {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: rgb(247, 246, 244) rgb(252, 252, 254) transparent transparent;
  box-sizing: border-box;
  animation: rotation2 1s linear infinite;
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: orange blue transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader::after,
.loader::before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent transparent #FF3D00 #FF3D00;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}

.loader::before {
  width: 32px;
  height: 32px;
  border-color: #31d710 #f0be19 transparent transparent;
  animation: rotation 1.5s linear infinite;
}

@keyframes rotation2 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}


.contactModal {
  border-radius: 10px;
}

.blogModal {
  border-radius: 10px;
  width: 80%;
  margin: auto;
}