.menu ul li .active {
    background: orange !important;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
}

.menu ul li a {
    display: block;
    font-size: 19px;
    font-weight: 600;
    color: #1E293B;
    margin: 10px 0px;
    text-transform: uppercase;
}

.profile-link {
    color: white !important;
    text-transform: capitalize !important;
    font-size: 15px !important;
    font-weight: 400 !important;
}