.hero-slide {
    position: relative;
}

.hero-slide::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, .3);
}